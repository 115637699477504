@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flatpickr/dist/flatpickr.css';
@import 'pikaday/css/pikaday.css';

:root {
    --blue: #1e90ff;
    --white: #ffffff;
    --black: black;
    --backgroundG3: #6e6e85;
    --backgroundG2: #2f2f39;
    --backgroundG1: #1e1f24;
    --background: #000000;
    --text: white;
    --primary: #fa773e;
    --secondary: #6e6e85;
    --secondaryHover: #fa773e;
    --error: red;
    --transparent: transparent;
    --active: rgb(68, 196, 68);
    --pending: rgb(255, 166, 0);
    --inactive: rgb(236, 90, 90);
}

body {
    color: var(--white);
}

h3 {
    font-size: 22px;
    font-weight: 600;
}

h2 {
    font-size: 26px;
    font-weight: 600;
}

h1 {
    font-size: 30px;
}

.active {
    background-color: var(--active) !important;
}

.inactive {
    background-color: var(--inactive) !important;
}

.pending {
    background-color: var(--pending) !important;
}

.bgcolor {
    background-color: var(--background)
}

.bgcolorG1 {
    background-color: var(--backgroundG1)
}

.bgcolorG2 {
    background-color: var(--backgroundG2)
}

.bgcolorG3 {
    background-color: var(--backgroundG3)
}

.primary {
    background-color: var(--primary) !important
}

.secondary {
    background-color: var(--secondary)
}

.tx-primary {
    color: var(--primary)
}

.tx-secondary {
    color: var(--secondary)
}

.tx-black {
    color: var(--black)
}

.tx-active {
    color: var(--active) !important;
}

.tx-inactive {
    color: var(--inactive) !important;
}

.tx-pending {
    color: var(--pending) !important;
}

p {
    color: var(--white);
}

ul {
    list-style-type: disc;
}

.white {
    color: var(--white);
}

.separator {
    display: flex;
    width: 100%;
    height: 5px;
    border-bottom: 1px solid var(--white);
    margin: 5px 0 10px 0;
}

input {
    color: var(--black);
    background: #3a3a3a;
    border: 1px solid #797979;
    border-radius: 4px;
    height: 35px;
    color: var(--white);
}

.form-error {
    color: var(--error);
}

.fullwidth {
    width: 100%;
}

.flex-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.flex-center-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-justify-end {
    display: flex;
    justify-content: end;
    align-items: center;
}

.base-button {
    width: 200px;
    height: 40px;
    border: 1px solid var(--transparent);
    border-radius: 8px;
    color: var(--black);
    font-weight: 600;
    background-color: var(--secondary)
}

.base-button:hover {
    background-color: var(--secondaryHover);
}
.base-button-sm {
    width: 80px;
    height: 40px;
    border: 1px solid var(--transparent);
    border-radius: 8px;
    color: var(--black);
    font-weight: 600;
    background-color: var(--secondary)
}

.base-button-sm:hover {
    background-color: var(--secondaryHover);
}

.base-button:disabled {
    background-color: var(--backgroundG3);
}

.icon-btn {
    background-color: var(--secondary);
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 8px;
    align-items: center;

    color: var(--black);
    font-weight: 600;
    justify-content: center;
}

.icon-btn:hover {
    background-color: var(--secondaryHover);
}

.icon-btn-rect {
    background-color: var(--secondary);
    width: 50px;
    height: 36px;
    display: flex;
    border-radius: 8px;
    align-items: center;

    color: var(--black);
    font-weight: 600;
    justify-content: center;
}

.icon-btn-rect:hover {
    background-color: var(--secondaryHover);
}


/** SYSTEM MODALE */

.modale {
    width: 800px;
    top: 50px;
    position: fixed;
    z-index: 100;
    inset: 40px 0;
    margin: 0 auto;
}

.modale-content {
    background-color: var(--backgroundG1);
}

.system-head {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
}

.system-full {
    border-radius: 8px;
}

.side-border {
    background-color: var(--primary);
    width: 2px;
}

/** CIRCLES */
.active-circle-sm {
    background-color: var(--active);
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.inactive-circle-sm {
    background-color: var(--inactive);
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.pending-circle-sm {
    background-color: var(--pending);
    width: 25px;
    height: 25px;
    border-radius: 100%;
}

.active-circle-md {
    background-color: var(--active);
    width: 25px;
    height: 25px;
    border-radius: 100%;
}

.inactive-circle-md {
    background-color: var(--inactive);
    width: 25px;
    height: 25px;
    border-radius: 100%;
}

.pending-circle-md {
    background-color: var(--pending);
    width: 25px;
    height: 25px;
    border-radius: 100%;
}